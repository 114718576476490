import { Text } from '@crimson-education/core-shared-ui';
import { Modal as AntdModal } from 'antd';
import { StyledButtonOutlined } from 'src/components/CrButton';
import styled from 'styled-components';

export const Modal = styled(AntdModal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
`;

interface SkipConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onSkip: () => void;
  sessionId: number;
}

const UpdateRoadmapMissionModal = ({ open, onClose, onSkip, sessionId }: SkipConfirmModalProps): JSX.Element => {
  const domain = window?.xprops?.domain || 'http://localhost:3000';
  return (
    <Modal width={452} open={open} footer={null} onCancel={onClose}>
      <div style={{ padding: '24px', borderRadius: '12px' }}>
        <Text color="#1D1E2B" fontSize={24} fontWeight={600} style={{ textAlign: 'center' }}>
          Update Roadmap Missions
        </Text>
        <Text color="#1D1E2B" fontSize={14} fontWeight={500} style={{ marginTop: '32px', textAlign: 'center' }}>
          It appears that your Roadmap isn’t fully updated. You can easily convert ISM/RMM meeting notes to Roadmap
          missions with just one click! This step is crucial as Copilot will incorporate these missions into the Initial
          Strategy Roadmap.
        </Text>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '36px', gap: '8px' }}>
          <StyledButtonOutlined onClick={onSkip} fontSize={'14px'}>
            Skip
          </StyledButtonOutlined>
          <StyledActionButton href={`${domain}/session/${sessionId}/agenda?type=convertMission`}>
            <span style={{ color: '#fff', fontSize: '14px', fontWeight: 700 }}>Convert Notes to Missions</span>
          </StyledActionButton>
        </div>
      </div>
    </Modal>
  );
};
export default UpdateRoadmapMissionModal;

const StyledActionButton = styled.a`
  background: var(--color-indigo);
  border: var(--border-nocolor) rgb(64, 68, 184);
  width: 223px;
  height: 40px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
