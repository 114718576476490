import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { SKIP_SESSION_UPDATES } from 'src/graphql/Copilot';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSkipSession = (): any => {
  const { studentCenterApiClient } = useContext(ApolloClientContext);
  return useMutation<boolean, { input: { sessionId: string } }>(SKIP_SESSION_UPDATES, {
    client: studentCenterApiClient,
  });
};
