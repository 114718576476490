import { gql } from '@apollo/client';

export const GET_SESSION_MISSION_RECOMMENDATIONS = gql`
  query GetSessionMissionRecommendations($sessionId: ID!) {
    missionRecommendationsBySession(sessionId: $sessionId) {
      id
      type
      subcategory
      title
      status
    }
  }
`;

export interface MissionUpdateRecommendation {
  id: string;
  title: string;
  subcategory: string;
  status: string | null;
  type: string | null;
}

export const SKIP_SESSION_UPDATES = gql`
  mutation SkipSessionUpdates($input: MissionUpdatesSkipInput!) {
    missionUpdatesSkip(input: $input) {
      success
    }
  }
`;
