import { Dropdown, MenuProps } from 'antd';
import { EntryPointCheckType } from 'src/types/global';
import { PdfViewButton, RoadmapPdfButton } from './style';

const RoadmapReportPdfComponent = ({ entryPoints }: { entryPoints: EntryPointCheckType }): JSX.Element => {
  const reports = entryPoints.reportLinks;
  if (!reports || reports.length === 0) return <></>;
  if (reports.length > 1) {
    const items: MenuProps['items'] = [];
    const createMenuItem = (title: string, signedUrl: string) => ({
      key: title,
      label: (
        <PdfViewButton onClick={() => window.open(signedUrl, '_blank')}>
          <img style={{ marginRight: '5px', height: '20px', width: '20px' }} src="/icons/pdf.svg" alt="pdf icon" />
          {title}
        </PdfViewButton>
      ),
    });
    reports.forEach((r) => {
      items.push(createMenuItem(r.title, r.signedUrl));
    });
    return (
      <Dropdown menu={{ items }} placement="bottom">
        <RoadmapPdfButton>View Roadmap Report</RoadmapPdfButton>
      </Dropdown>
    );
  } else {
    return (
      <PdfViewButton onClick={() => window.open(reports[0].signedUrl, '_blank')}>
        <img style={{ marginRight: '5px', height: '20px', width: '20px' }} src="/icons/pdf.svg" alt="pdf icon" />{' '}
        {reports[0].title}
      </PdfViewButton>
    );
  }
};

export default RoadmapReportPdfComponent;
