import { Button } from 'antd';
import { StyledButtonFilled, StyledButtonOutlined } from 'src/components/CrButton';
import styled, { css } from 'styled-components';

export const RoadmapPdfButton = styled(StyledButtonFilled)`
  font-size: 14px;
  height: 40px;
  &:hover {
    color: white;
    border: var(--border-nocolor) rgb(64, 68, 184);
    background-color: #2d30b4;
    box-shadow: 0px 6px 10px -1px rgba(29, 30, 43, 0.07);
  }
`;

export const EditButton = styled(Button)`
  font-size: 12px;
  border: none;
  color: #73747d;
  font-weight: 500;
  background: transparent;
  box-shadow: none;
  &:hover {
    background: transparent;
  }
`;

const buttonFocusedStyle = css`
  background: var(--color-indigo);
  color: white;
  svg {
    fill: white;
  }
`;

export const PdfViewButton = styled(StyledButtonOutlined)`
  font-size: 14px;
  background: white;
  padding: 10px 24px;
  margin-right: 8px;
  height: 40px;
  box-shadow: none;
  &:hover svg {
    fill: white;
  }
  &:focus {
    border: 2px solid #e3e7ed;
  }
  &.focus {
    ${buttonFocusedStyle}
  }
`;
